.not-found {
  padding: 40px;
  text-align: center;
}

.error-template {
  max-width: 930px;
  margin: auto auto;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.error-template h1 {
  margin-top: 0;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.error-template h2 {
  font-size: 18px;
  color: #666;
}

.error-details {
  margin-bottom: 20px;
  font-size: 16px;
  color: #999;
}

.error-actions {
  margin-top: 20px;
  text-align: center;
}

.error-actions .btn {
  margin: 0 10px;
}
.typed-text {
  overflow: hidden;
  border-right: 0.15em solid black;
  white-space: nowrap; /* no wrap--> impedisce al testo di andare a capo  */
  letter-spacing: 0.15em; /* Spaziatura tra le lettere */
  animation: typing 3.5s steps(30, end),
    /* Anima la digitazione in 3.5 secondi */ blink-caret 0.75s step-end
      infinite; /* Cursore lampeggiante */
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}
